.info-popup {
  visibility: hidden;
  position: fixed;
  background-color: $infoPopUp-bg-color;
  right: calc(-1000px * var(--device-pixel-ratio));
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &.show {
    visibility: visible;
    right: 0;
    opacity: 1;
    z-index: 20;
  }
  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(336px * var(--device-pixel-ratio));
    width: 100%;
    min-height: calc(206px * var(--device-pixel-ratio));
    background:$infoPopUp-content-bg-color 0% 0% no-repeat padding-box;
    border: calc(1px * var(--device-pixel-ratio)) solid $infoPopUp-content-border-color;
    border-radius: calc(8px * var(--device-pixel-ratio));
    padding: calc(20px * var(--device-pixel-ratio));
    &-header {
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-bottom: calc(24px * var(--device-pixel-ratio));
      & .title {
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        font: 900 calc(22px * var(--device-pixel-ratio))/calc(26px * var(--device-pixel-ratio)) "SF Pro Text Light";
        letter-spacing: calc(0.88px * var(--device-pixel-ratio));
        color: $infoPopUp-content-color;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $infoPopUp-content-text-shadow-color;
      }
      & .circled-button {
        z-index: 1;
      }
    }
    & .info-popup-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: calc(32px * var(--device-pixel-ratio));
      color: $infoPopUp-content-color;
      & .description {
        width: 100%;
        text-align: center;
        font: 17px/20px "SF Pro Text Bold";
        letter-spacing: calc(0.68px * var(--device-pixel-ratio));
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $infoPopUp-content-text-shadow-color;
      }
    }
    & .info-popup-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      & .popup-btn {
        &:not(:last-child) {
          margin-right: calc(8px * var(--device-pixel-ratio));
        }
      }
    }
  }
  &.reconnect {
    & .info-popup-content {
      max-width: calc(450px * var(--device-pixel-ratio));
      min-height: fit-content;
    }
  }
}

