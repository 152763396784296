.board {
  perspective: calc(929px * var(--device-pixel-ratio));
  width: calc(788px * var(--device-pixel-ratio));
  margin: 0 calc(150px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(150px * var(--device-pixel-ratio));
  position: relative;
  height: calc(160px * var(--device-pixel-ratio));
  &-info {
    transition: transform 0.5s, margin 0.5s;
    transform: rotate3d(0,0,0, 0deg);
    transform-origin: bottom;
    &.threeD {
      transform: rotate3d(-10,0,0,-45deg);
      transform-origin: bottom;
    }
  }
  &.enabled-bet-areas {
    & .board-svg {
      &-item {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }
  &-svg {
    &-item {
      cursor: pointer;
      &:hover {
        &.andar  {
          & g {
            fill: rgba(166, 13, 13, 0.7);
          }
        }
        &.bahar  {
          & g {
            fill: rgba(2,81,89,0.7);
          }
        }
        &.joker {
          & g {
            fill: rgba(29,27,45,0.7);
          }
        }
        &.variants {
          & g {
            fill: rgba(88,88,97,0.7);
          }
        }
      }
      &.active , &:active {
        &.andar  {
          & g {
            fill: rgba(166, 13, 13, 1);
          }
        }
        &.bahar  {
          & g {
            fill: rgba(2,81,89,1);
          }
        }
        &.joker {
          & g {
            fill: rgba(29,27,45,1);
          }
        }
        &.variants {
          & g {
            fill: rgba(88,88,97,1);
          }
        }
      }
    }
  }
}

.middlePc {
  .board {
    width: calc(710px * var(--device-pixel-ratio));
    margin: 0 calc(106px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(106px * var(--device-pixel-ratio));
    height: calc(140px * var(--device-pixel-ratio));
  }
}

.smallPc {
  .board {
    width: calc(710px * var(--device-pixel-ratio));
    height: calc(140px * var(--device-pixel-ratio));
    margin: 0 calc(11px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(11px * var(--device-pixel-ratio));
  }
}

.tab {
  .board {
    order: 1;
    width: calc(710px * var(--device-pixel-ratio));
    margin: 0 auto;
    height: calc(140px * var(--device-pixel-ratio));
  }
}

@media only screen and (max-width: 540px) {
  .board {
    width: 89.066vw;
    order: 1;
    margin: 0 auto;
    height: auto;
    max-width: 50.07vh;
    max-height: 21.33vw;
    &-svg {
      width: 100%;
      height: 100%;
    }
  }
}
