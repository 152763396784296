.winners {
  position: absolute;
  -ms-overflow-style: none;
  left: calc(20px * var(--device-pixel-ratio));
  bottom: calc(219px * var(--device-pixel-ratio));
  -webkit-mask-image: -webkit-gradient(linear, left 143, left top, from(black), to(rgba(0, 0, 0, 0)));
  transition: height 0.7s ease-in-out;
  z-index: 3;
  &.hide {
    opacity: 0;
    transition: opacity .5s;
  }
  & div {
    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 calc(6px * var(--device-pixel-ratio)) rgba(0,0,0,0);
      background-color: transparent;

    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  
  .title {
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
    letter-spacing: calc(0.56px * var(--device-pixel-ratio));
    color: $winnerList-title-color;
    text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $winnerList-title-shadow-color;
    text-transform: uppercase;
    & .icon {
      width: calc(18px * var(--device-pixel-ratio));
      height: calc(18px * var(--device-pixel-ratio));
    }
  }
}

.winner-table {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &-header {
    height: calc(32px * var(--device-pixel-ratio));
    &-content {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      &-item {
        padding: calc(4px * var(--device-pixel-ratio));
        &:first-child {
          text-align: right;
          font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.56px * var(--device-pixel-ratio));
          color: $winnerList-table-thead-td-color;
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $winnerList-table-thead-td-shadow-color;
          & .icon {
            vertical-align: middle;
            width: calc(14px * var(--device-pixel-ratio));
            height: calc(14px * var(--device-pixel-ratio));
          }

        }
        &:last-child {
          text-align: left;
          font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.56px * var(--device-pixel-ratio));
          color: $winnerList-table-thead-td-stack-color;
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $winnerList-table-thead-td-shadow-color;

        }
      }
    }
  }
  &-body {
    &-content {
      display: flex;
      align-items: center;
      width: 100%;
      &-item{
        padding: calc(4px * var(--device-pixel-ratio));
        flex: 1;
        &:first-child {
          text-align: right;
          font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.56px * var(--device-pixel-ratio));
          color: $winnerList-table-thead-td-color;
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $winnerList-table-thead-td-shadow-color;
          & .icon {
            vertical-align: middle;
          }

        }
        &:last-child {
          text-align: left;
          font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.56px * var(--device-pixel-ratio));
          color: $winnerList-table-thead-td-stack-color;
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $winnerList-table-thead-td-shadow-color;

        }
      }
    }
  }
}

.smallPc {
  .winners {
    & > div {
      max-width: calc(250px * var(--device-pixel-ratio));
    }
    bottom: calc(178px * var(--device-pixel-ratio));
    left: calc(16px * var(--device-pixel-ratio));
    max-height: calc(340px * var(--device-pixel-ratio));
    & .title {
      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
      text-align: center;
      flex-wrap: wrap;
      & span {
        &:nth-child(1) {
          width: 100%;
        }
      }
      &-divider {
        display: none;
      }
    }
  }
  .winner-table {
    &-header {
      &-content {
        &-item {
          &:first-child {
            font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.48px * var(--device-pixel-ratio));
            & .icon {
              width: calc(14px * var(--device-pixel-ratio));
              height: calc(14px * var(--device-pixel-ratio));
            }
  
          }
          &:last-child {
            font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.48px * var(--device-pixel-ratio));
          }
        }
      }
    }
    &-body {
      &-content {
        &-item {
          &:first-child {
            font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
          }
          &:last-child {
            font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}

.tab {
  .winners {
    bottom: unset;
    top: calc(168 * var(--device-pixel-ratio));
    left: calc(16px * var(--device-pixel-ratio));

    & > div {
      max-width: 250px;
    }

    & .title {
      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
      text-align: center;
      flex-wrap: wrap;
      & span {
        &:nth-child(1) {
          width: 100%;
        }
      }
      &-divider {
        display: none;
      }
    }
  }
  .winner-table {
    &-header {
      &-content {
        &-item {
          &:first-child {
            font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.48px * var(--device-pixel-ratio));
            & .icon {
              width: calc(14px * var(--device-pixel-ratio));
              height: calc(14px * var(--device-pixel-ratio));
            }
  
          }
          &:last-child {
            font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.48px * var(--device-pixel-ratio));
          }
        }
      }
    }
    &-body {
      &-content {
        &-item {
          &:first-child {
            font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
          }
          &:last-child {
            font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .winners {
    bottom: unset;
    top: 55px;
    max-height: 280px;
    left: calc(16px * var(--device-pixel-ratio));

    & > div {
      max-width: 150px;
    }
    
    & .title {
      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
      text-align: center;
      flex-wrap: wrap;
      & span {
        &:nth-child(1) {
          width: 100%;
        }
      }
      &-divider {
        display: none;
      }
    }
  }
  .winner-table {
    &-header {
      &-content {
        &-item {
          &:first-child {
            font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
            & .icon {
              width: calc(12px * var(--device-pixel-ratio));
              height: calc(12px * var(--device-pixel-ratio));
            }
  
          }
          &:last-child {
            font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
          }
        }
      }
    }
    &-body {
      &-content {
        &-item {
          &:first-child {
            font: calc(8px * var(--device-pixel-ratio))/calc(10px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.32px * var(--device-pixel-ratio));
          }
          &:last-child {
            font: calc(8px * var(--device-pixel-ratio))/calc(10px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.32px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}