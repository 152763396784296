.cards {
    width: calc(500px * var(--device-pixel-ratio));
    height: calc(246px * var(--device-pixel-ratio));
    background: #1D1B2DE6 0% 0% no-repeat padding-box;
    border: 1px solid #87879480;
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    gap: 12px;

    &-andar,
    &-joker,
    &-bahar {
        height: 70px;
        display: flex;
        justify-content: center;

        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;

        .cards-slider {
            display: flex;
            
            gap: 13px;
            align-items: center;

            .swiper-free-mode {
                width: 418px;
                height: 56px;
            }
        }

        .swiper-wrapper {
            max-width: fit-content;
            display: flex;
            gap: calc(6px * var(--device-pixel-ratio));

            .swiper-slide {
                width: 34px;
                height: 50px;

                &-active {
                    .flip-card-inner {
                        border: 2px solid #FFCC00;
                        border-radius: 4px;
                    }
                }

                .cards-list-item {
                    width: 34px;
                    height: 54px;

                    .card {
                        width: calc(34px * var(--device-pixel-ratio));
                        height: calc(57px * var(--device-pixel-ratio));

                        & .cardIcon {
                            height: 100%;
                            font-size: calc(53px * var(--device-pixel-ratio));
                        }
                    }
                }
            }

        }

        & .prev-button,
        .next-button {
            width: 24px;
            height: 24px;

            & svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    & span {
        text-decoration: underline;
        font: normal normal bold 12px/14px SF Pro Text;
        font-size: 12px;
        letter-spacing: 0.48px;
        color: #FFFFFF;
        text-shadow: 1px 1px 2px #000000;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 7px;
    }
}

.smallPc {
    .cards {
        width: calc(240px * var(--device-pixel-ratio));
        height: calc(226px * var(--device-pixel-ratio));
        background: #1D1B2DE6 0% 0% no-repeat padding-box;
        border: 1px solid #87879480;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        gap: 6px;
        &-bahar {
            .cards-slider {
                margin-top: 5px;
            }
        }
        &-andar {
            .cards-history{
                position: relative;
                bottom: 4px;
            }
        }

        &-andar,
        &-joker,
        &-bahar {
            height: 67px;
            display: flex;
            justify-content: center;

            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;

            .cards-slider {
                display: flex;
                justify-content: space-between;
                // gap: 20px;
                align-items: center;

                .swiper-free-mode {
                    width: 175px;
                    height: 54px;
                }
            }

            .swiper-wrapper {
                max-width: fit-content;
                display: flex;
                gap: calc(6px * var(--device-pixel-ratio));

                .swiper-slide {
                    width: 29px;
                    height: 48px;

                    &-active {
                        .flip-card-inner {
                            border: 2px solid #FFCC00;
                            border-radius: 4px;
                        }
                    }

                    .cards-list-item {
                        width: 29px;
                        height: 48px;

                        .card {
                            width: calc(29px * var(--device-pixel-ratio));
                            height: calc(48px * var(--device-pixel-ratio));

                            & .cardIcon {
                                height: 100%;
                                font-size: calc(44px * var(--device-pixel-ratio));
                            }
                        }
                    }
                }

            }

            & .prev-button,
            .next-button {
                width: 24px;
                height: 24px;

                & svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        & span {
            text-decoration: underline;
            font: normal normal bold 12px/14px SF Pro Text;
            font-size: 12px;
            letter-spacing: 0.48px;
            color: #FFFFFF;
            text-shadow: 1px 1px 2px #000000;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 7px;
            vertical-align: bottom;
        }
    }
}

.tab {
    .cards {
        width: calc(280px * var(--device-pixel-ratio));
        height: calc(230px * var(--device-pixel-ratio));
        background: #1D1B2DE6 0% 0% no-repeat padding-box;
        border: 1px solid #87879480;
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        gap: 8px;


        &-andar,
        &-joker,
        &-bahar {
            height: 58px;
            display: flex;
            justify-content: center;

            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;

            .cards-slider {
                display: flex;
                justify-content: space-between;
                // gap: 20px;
                align-items: center;

                .swiper-free-mode {
                    width: 282px;
                    height: 55px;
                }
            }

            .swiper-wrapper {
                max-width: fit-content;
                display: flex;
                gap: calc(5px * var(--device-pixel-ratio));

                .swiper-slide {
                    width: 29px;
                    height: 48px;

                    &-active {
                        .flip-card-inner {
                            border: 2px solid #FFCC00;
                            border-radius: 4px;
                        }
                    }

                    .cards-list-item {
                        width: 29px;
                        height: 48px;

                        .card {
                            width: calc(29px * var(--device-pixel-ratio));
                            height: calc(48px * var(--device-pixel-ratio));

                            & .cardIcon {
                                height: 100%;
                                font-size: calc(44px * var(--device-pixel-ratio));
                            }
                        }
                    }
                }

            }

            & .prev-button,
            .next-button {
                width: 24px;
                height: 24px;

                & svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        & span {
            text-decoration: underline;
            font: normal normal bold 12px/14px SF Pro Text;
            font-size: 12px;
            letter-spacing: 0.48px;
            color: #FFFFFF;
            text-shadow: 1px 1px 2px #000000;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 0px;
            vertical-align: bottom;
        }
    }
}

@media only screen and (max-width: 540px) {
    .cards {
        order: 2;
        position: unset;
        width: 100vw;
        flex-direction: row;
        flex-wrap: nowrap;
        height: fit-content;
        justify-content: space-between;
        border-radius: 0;
        padding: 5px;
        &-andar,
        &-joker,
        &-bahar {
            height: fit-content;
            flex-direction: inherit;
            align-items: center;
            justify-content: center;
            gap: 6px;
            display: flex;
            flex-wrap: wrap;
            .swiper-wrapper {
                max-width:inherit;
                gap: 2px;
                .swiper-slide {
                    width: 32px;
                    height: 46px;

                    &-active {
                        .flip-card-inner {
                            border: 2px solid #FFCC00;
                            border-radius: 4px;
                        }
                    }

                    .cards-list-item {
                        width: 28px;
                        height: 40px;

                        .card {
                            width: calc(30px * var(--device-pixel-ratio));
                            height: calc(40px * var(--device-pixel-ratio));

                            & .cardIcon {
                                height: 100%;
                                font-size: calc(40px * var(--device-pixel-ratio));
                            }
                        }
                    }
                }

            }
        }

        &-andar {
            width: calc((100vw - 100px) / 2);
            gap: 6px;
            .cards-slider {
                width: 100%;
            }
        }
        &-joker {
            width: 58px;
            .cards-slider {
                width: 100%;
            }
        }
        &-bahar {
            width: calc((100vw - 100px) / 2);        
            .cards-slider {
                width: 100%;
            }
        }
    }
}