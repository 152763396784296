.cards {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(224px * var(--device-pixel-ratio));
  left: calc(0px * var(--device-pixel-ratio));
  background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  border: calc(1px * var(--device-pixel-ratio)) solid #87879480;
  border-left-color: transparent;
  border-radius: calc(0px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio));
  padding: calc(20px * var(--device-pixel-ratio));
  &-list {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
    &-item {
      width: calc(46px * var(--device-pixel-ratio));
      height: calc(69px * var(--device-pixel-ratio));
      &:not(:last-child){
        margin-right: calc(10px * var(--device-pixel-ratio));
      }

      & .flip-card {
        width: 100%;
        height: 100%;
        perspective: calc(1000px * var(--device-pixel-ratio));
      }

      & .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
      }

      & .flip-card.active .flip-card-inner {
        transform: rotateY(180deg);
      }

      & .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      & .flip-card-front, & .flip-card-back {
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & .flip-card-back {
        transform: rotateY(180deg);
        & .cardIcon {
          height: 100%;
          font-size: calc(64px * var(--device-pixel-ratio));
          position: absolute;
          top: 0;
          box-shadow: none;
          background: transparent;
        }
      }
    }
  }
}

.middlePc {
  .cards {
    top: calc(160px * var(--device-pixel-ratio));
    &-list {
      &-item {
        width: calc(34px * var(--device-pixel-ratio));
        height: calc(50px * var(--device-pixel-ratio));
        &:not(:last-child){
          margin-right: calc(8px * var(--device-pixel-ratio));
        }

        & .flip-card-back {
          & .cardIcon {
            font-size: calc(50px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}

.smallPc {
  .cards {
    top: calc(224px * var(--device-pixel-ratio));
    &-list {
      &-item {
        width: calc(34px * var(--device-pixel-ratio));
        height: calc(50px * var(--device-pixel-ratio));

        &:not(:last-child) {
          margin-right: calc(8px * var(--device-pixel-ratio));
        }

        & .flip-card-back {
          & .cardIcon {
            font-size: calc(48px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}

.tab {
  .cards {
    display: flex;
    flex-direction: row;
    left: calc(-2px * var(--device-pixel-ratio));
    top: calc(140px * var(--device-pixel-ratio));
    &-list {
      &-item {
        width: calc(28px * var(--device-pixel-ratio));
        height: calc(40px * var(--device-pixel-ratio));
        &:not(:last-child){
          margin-right: calc(4px * var(--device-pixel-ratio));
        }
        & .flip-card-back {
          & .cardIcon {
            font-size: calc(40px * var(--device-pixel-ratio));
            position: absolute;
            top: 0;
            box-shadow: none;
            background: transparent;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .cards {
    &-list {
      &-item {
        width: 28px;
        height: 40px;
        &:not(:last-child){
          margin-right: calc(4px * var(--device-pixel-ratio));
        }
        & .flip-card-back {

          & .cardIcon {

            font-size: calc(40px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}