.game-history {
  width: calc(416px * var(--device-pixel-ratio));
  height: calc(250px * var(--device-pixel-ratio));
  background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 0px 8px 0px 0px;
  padding: calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) 0 calc(20px * var(--device-pixel-ratio));
  display: flex;
  flex-direction: column;
  &-last-results {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(8px * var(--device-pixel-ratio));
    border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
    padding-bottom: calc(12px * var(--device-pixel-ratio));
    margin-bottom: calc(12px * var(--device-pixel-ratio));
    height: calc(36px * var(--device-pixel-ratio));
    &-item {
      width: calc(290px * var(--device-pixel-ratio));
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: calc(16px * var(--device-pixel-ratio));
        & .title {
          font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.4px * var(--device-pixel-ratio));
          color: #FFFFFF;
        }
      }
      &-round-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: calc(16px * var(--device-pixel-ratio));
        & .round-info-item {
          padding-left: calc(8px * var(--device-pixel-ratio));
          &:last-child {
            margin-left: calc(8px * var(--device-pixel-ratio));
          }
          & .title, & .time {
            text-align: left;
            font: calc(10px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
            color: #FFFFFF;
          }
        }
      }
      &-round-cards {
        display: flex;
        flex-direction: column;
        gap: calc(4px * var(--device-pixel-ratio));
        & .history-card-list {
          width: 100%;
          height: calc(82px * var(--device-pixel-ratio));
          padding: calc(8px * var(--device-pixel-ratio)) ;
          & .player {
            text-align: left;
            font:  bold calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
            color: #FFFFFF;
            text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
          }
          & .cards-element {
            &:nth-child(1) {
              padding-bottom: 0;
              border-bottom: 0;
            }
            & .title {
              display: none;
            }
            & .cards-list {
              & .cards-list-item  {
                width: calc(34px * var(--device-pixel-ratio));
                height: calc(50px * var(--device-pixel-ratio));
                &:not(:last-child) {
                  margin-right:calc(6px * var(--device-pixel-ratio)) ;
                }
                & .flip-card-back {
                  & .cardIcon{
                    font-size: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &-icon {
    border: calc(1px * var(--device-pixel-ratio)) solid;
    border-radius: 100%;
    letter-spacing: calc(0.56px * var(--device-pixel-ratio));
    display: flex;
    align-items: center;
    justify-content: center;
    transition: margin-bottom 0.3s ease-in-out;
    &.large {
      font: bold calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Bold";
      width: calc(24px * var(--device-pixel-ratio));
      height: calc(24px * var(--device-pixel-ratio));
      cursor: pointer;
    }
    &.active {
      margin-bottom: calc(8px * var(--device-pixel-ratio));
    }
    &.small {
      font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
      width: calc(18px * var(--device-pixel-ratio));
      height: calc(18px * var(--device-pixel-ratio));
    }
    &.A {
      border-color: #A60D0D;
      color: #A60D0D;
    }
    &.B {
      border-color: #025159;
      color: #025159;
    }
  }
  &-header {
    & .list {
      display: flex;
      list-style: none;
      justify-content: center;
      align-items: center;
      border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
      padding-bottom: calc(12px * var(--device-pixel-ratio));
      margin-bottom: calc(12px * var(--device-pixel-ratio));
      gap: calc(15px * var(--device-pixel-ratio));
      height: calc(30px * var(--device-pixel-ratio));
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio))"SF Pro Text Medium";
        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
        color: #FFFFFF;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
        & .icon, & .game-history-icon {
          margin-right: calc(4px * var(--device-pixel-ratio));
        }
        & .icon {
          width: calc(12px * var(--device-pixel-ratio));
          height: calc(14px * var(--device-pixel-ratio));

          & svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  &-data {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: calc(11px * var(--device-pixel-ratio));
    border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
    height: 100%;
    margin-bottom: calc(8px * var(--device-pixel-ratio));
    & .info-box {
      width: calc(109px * var(--device-pixel-ratio));
      height: calc(80px * var(--device-pixel-ratio));
      padding-right: calc(11px * var(--device-pixel-ratio));
      margin-right: calc(11px * var(--device-pixel-ratio));
      border-right: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
      font: calc(14px * var(--device-pixel-ratio))/calc(24px * var(--device-pixel-ratio)) "SF Pro Text Medium";
      letter-spacing: calc(0.56px * var(--device-pixel-ratio));
      color: rgba(255, 255, 255, 1);
      &-item {
        white-space: nowrap;
      }
    }
  }
  &-table {
    width: calc(248px * var(--device-pixel-ratio));
    height: calc(104px * var(--device-pixel-ratio));
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    & .element {
      width: calc(14px * var(--device-pixel-ratio));
      height: calc(14px * var(--device-pixel-ratio));
      margin: calc(2px * var(--device-pixel-ratio));
      position: relative;
      &:before {
        content: "";
        background: rgba(135, 135, 148, 1) 0% 0% no-repeat padding-box;
        border: 1px solid rgba(0, 0, 0, 1);
        width: calc(4px * var(--device-pixel-ratio));
        height: calc(4px * var(--device-pixel-ratio));
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &.A, &.B, &.T {
        border: 2px solid ;
        border-radius: 100%;
        &:before {
          content: none;
        }
      }
      &.A {
        border-color: #A60D0D;
      }
      &.B {
        border-color: #025159;
      }

      &.fill {
        &.A {
          background: #A60D0D;
        }
        &.B {
          background: #025159;
        }
        color: #fff;
        font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) black;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.middlePc {
  .game-history {
    width: calc(274px * var(--device-pixel-ratio));
    height: calc(218px * var(--device-pixel-ratio));
    padding: calc(16px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) 0 calc(16px * var(--device-pixel-ratio));
    &-last-results {
      gap: calc(6px * var(--device-pixel-ratio));
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      margin-bottom: calc(17px * var(--device-pixel-ratio));
      height: calc(25px * var(--device-pixel-ratio));
    }
    &-icon {
      letter-spacing: calc(0.52px * var(--device-pixel-ratio));
      &.large {
        font: bold calc(13px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        width: calc(19px * var(--device-pixel-ratio));
        height: calc(19px * var(--device-pixel-ratio));
      }
      &.small {
        font: bold calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        width: calc(15px * var(--device-pixel-ratio));
        height: calc(15px * var(--device-pixel-ratio));
        letter-spacing: calc(0.44px * var(--device-pixel-ratio));
      }
    }
    &-header {
      & .list {
        padding-bottom: calc(8px * var(--device-pixel-ratio));
        margin-bottom: calc(16px * var(--device-pixel-ratio));
        gap: calc(8px * var(--device-pixel-ratio));
        height: calc(23px * var(--device-pixel-ratio));
        &-item {
          font: calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio))"SF Pro Text Medium";
          letter-spacing: calc(0.44px * var(--device-pixel-ratio));
          & .icon, & .game-history-icon {
            margin-right: calc(2px * var(--device-pixel-ratio));
          }
          & .icon {
            width: calc(11px * var(--device-pixel-ratio));
            height: calc(13px * var(--device-pixel-ratio));
          }
        }
      }
    }
    &-data {
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      margin-bottom: calc(8px * var(--device-pixel-ratio));
      & .info-box {
        width: calc(56px * var(--device-pixel-ratio));
        height: calc(104px * var(--device-pixel-ratio));
        padding-right: calc(8px * var(--device-pixel-ratio));
        margin-right: calc(6px * var(--device-pixel-ratio));
        font: bold calc(11px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        letter-spacing: calc(0.44px * var(--device-pixel-ratio));
        &-item {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          white-space: nowrap;

          & .percent {
            display: block;
            width: 100%;
            text-align: center;
          }
          & .divider {
            display: none;
          }
        }
      }
    }
    &-table {
      width: calc(242px * var(--device-pixel-ratio));
      height: calc(104px * var(--device-pixel-ratio));
      & .element {
        width: calc(12px * var(--device-pixel-ratio));
        height: calc(12px * var(--device-pixel-ratio));
        margin: calc(1px * var(--device-pixel-ratio));

        &.fill {
          font: bold calc(8px * var(--device-pixel-ratio))/calc(8px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        }
      }
    }
  }
}

.smallPc {
  .game-history {
    width: calc(274px * var(--device-pixel-ratio));
    height: calc(218px * var(--device-pixel-ratio));
    padding: calc(16px * var(--device-pixel-ratio)) calc(18px * var(--device-pixel-ratio));
    &-last-results {
      gap: calc(3px * var(--device-pixel-ratio));
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      margin-bottom: calc(17px * var(--device-pixel-ratio));
      height: calc(31px * var(--device-pixel-ratio));
    }
    &-icon {
      letter-spacing: calc(0.52px * var(--device-pixel-ratio));
      &.large {
        font: bold calc(13px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        width: calc(19px * var(--device-pixel-ratio));
        height: calc(19px * var(--device-pixel-ratio));
      }
      &.small {
        font: bold calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        width: calc(15px * var(--device-pixel-ratio));
        height: calc(15px * var(--device-pixel-ratio));
      }
    }
    &-header {
      & .list {
        padding-bottom: calc(8px * var(--device-pixel-ratio));
        margin-bottom: calc(16px * var(--device-pixel-ratio));
        gap: calc(4px * var(--device-pixel-ratio));
        height: calc(23px * var(--device-pixel-ratio));
        &-item {
          font: calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio))"SF Pro Text Medium";
          letter-spacing: calc(0.44px * var(--device-pixel-ratio));
          & .icon, & .game-history-icon {
            margin-right: calc(2px * var(--device-pixel-ratio));
          }
          & .icon {
            width: calc(11px * var(--device-pixel-ratio));
            height: calc(13px * var(--device-pixel-ratio));
          }
        }
      }
    }
    &-data {
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      & .info-box {
        width: calc(46px * var(--device-pixel-ratio));
        height: calc(104px * var(--device-pixel-ratio));
        padding-right: calc(10px * var(--device-pixel-ratio));
        margin-right: calc(6px * var(--device-pixel-ratio));
        font: calc(11px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.44px * var(--device-pixel-ratio));
        gap: calc(5px * var(--device-pixel-ratio));
        display: flex;
        flex-direction: column;
        &-item {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          white-space: nowrap;

          & .percent {
            display: block;
            width: 100%;
            text-align: center;
          }
          & .divider {
            display: none;
          }
        }
      }
    }
    &-table {
      width: calc(180px * var(--device-pixel-ratio));
      height: calc(82px * var(--device-pixel-ratio));
      & .element {
        width: calc(12px * var(--device-pixel-ratio));
        height: calc(12px * var(--device-pixel-ratio));
        margin: calc(1px * var(--device-pixel-ratio));
        &.fill {
          font: bold calc(10px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        }
      }
    }
  }
}

.tab {
  .game-history {
    width: calc(240px * var(--device-pixel-ratio));
    height: calc(356px * var(--device-pixel-ratio));
    padding: calc(16px * var(--device-pixel-ratio));
    &-last-results {
      gap: calc(5px * var(--device-pixel-ratio));
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      margin-bottom: calc(16px * var(--device-pixel-ratio));
      height: calc(24px * var(--device-pixel-ratio));
      &-item {

        &-round-cards {
          & .history-card-list {
            gap: calc(8px * var(--device-pixel-ratio));
            & .cards-element {
              padding-right: calc(0px * var(--device-pixel-ratio));
              margin-right: calc(0px * var(--device-pixel-ratio));
              border-right: 0;
            }
          }
        }
      }
    }
    &-icon {
      letter-spacing: calc(0.44px * var(--device-pixel-ratio));
      &.large {
        font: bold calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        width: calc(16px * var(--device-pixel-ratio));
        height: calc(16px * var(--device-pixel-ratio));
      }
      &.small {
        font: bold calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        width: calc(15px * var(--device-pixel-ratio));
        height: calc(15px * var(--device-pixel-ratio));
      }
    }
    &-header {
      & .list {
        padding-bottom: calc(9px * var(--device-pixel-ratio));
        margin-bottom: calc(16px * var(--device-pixel-ratio));
        gap: calc(7px * var(--device-pixel-ratio));
        height: calc(24px * var(--device-pixel-ratio));
        &-item {
          font: calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio))"SF Pro Text Medium";
          letter-spacing: calc(0.44px * var(--device-pixel-ratio));
          & .icon, & .game-history-icon {
            margin-right: calc(2px * var(--device-pixel-ratio));
          }
          & .icon {
            width: calc(11px * var(--device-pixel-ratio));
            height: calc(13px * var(--device-pixel-ratio));
          }
        }
      }
    }
    &-data {
      padding-bottom: calc(11px * var(--device-pixel-ratio));
      border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
      flex-wrap: wrap;
      & .info-box {
        width: 100%;
        height: calc(20px * var(--device-pixel-ratio));
        padding-right: 0;
        padding-bottom: calc(8px * var(--device-pixel-ratio));
        margin-bottom: calc(16px * var(--device-pixel-ratio));
        margin-right: 0;
        border-right: 0;
        border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
        font: calc(10px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.4px * var(--device-pixel-ratio));
        display: flex;
        align-items: center;
        justify-content: space-around;
        &-item {
          & .divider {
            display: none;
          }
        }
      }
    }
    &-table {
      width: 100%;
      height: calc(94px * var(--device-pixel-ratio));
      & .element {
        width: calc(12px * var(--device-pixel-ratio));
        height: calc(12px * var(--device-pixel-ratio));
        margin: calc(1px * var(--device-pixel-ratio));
        &.fill {
          font: bold calc(10px * var(--device-pixel-ratio))/calc(10px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .game-history {
    width: 41.86vw;
    height: 24.28vh;
    padding: 2.66vw 2.66vw 0 2.66vw;
    &-last-results {
      gap: 0.53vw;
      padding-bottom: 0.899vh;
      margin-bottom: 0.899vh;
      height: 2.698vh;
      &-item {

        &-round-cards {
          & .history-card-list {
            & .cards-element {
              padding: calc(0px * var(--device-pixel-ratio));
              margin-right: calc(0px * var(--device-pixel-ratio));
              border-right: 0;
              border-color: transparent;
            }
          }
        }
      }
    }
    &-icon {
      letter-spacing: 0.149vw;
      &.large {
        font: bold 2.13vw/1.499vh "SF Pro Text Bold";
        width: 3.2vw;
        max-height: 3.2vw;
        height: 1.799vh;
        max-width: 1.799vh;
      }
      &.small {
        font: bold 2.13vw/1.499vh "SF Pro Text Bold";
        width: 3.2vw;
        max-height: 3.2vw;
        height: 1.799vh;
        max-width: 1.799vh;
      }
    }
    &-header {
      & .list {
        padding-bottom: 0.899vh;
        margin-bottom: 0.899vh;
        gap: 0.8vw;
        height: 2.698vh;
        &-item {
          font: 2.66vw/1.799vw "SF Pro Text Medium";
          letter-spacing: 0.1066vw;
          & .icon, & .game-history-icon {
            margin-right: 0.53vw;
          }
          & .icon {
            width: 2.66vw;
            height: 1.799vw ;
          }
        }
      }
    }
    &-data {
      padding-bottom: 0vw;
      flex-wrap: wrap;
      & .info-box {
        width: 100%;
        height: 2.248vh;
        padding-right: 0;
        padding-bottom: 0.899vw;
        margin-bottom: 0.899vw;
        margin-right: 0;
        border-right: 0;
        border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
        font: 1.866vw/1.799vh "SF Pro Text Medium";
        letter-spacing: 0.0746vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
        &-item {
          & .divider {
            display: none;
          }
        }
      }
    }
    &-table {
      width: 100%;
      height: 11.59vh;
      & .element {
        width: 2.133vw;
        max-height: 2.133vw;
        max-width: 1.199vh;
        height: 1.199vh;
        margin: 0.266vw;
        &:before {
          width: 1.066vw;
          height: 0.59vh;
          max-width: 0.59vh;
          max-height: 1.066vw;
          border-radius: 100%;
        }
        &.fill {
          font: bold 1.866vw/1.49vh "SF Pro Text Bold";
        }
      }
    }
  }
}