.history-round {
    display: flex;
    flex-direction: column;
    gap: calc(28px * var(--device-pixel-ratio));
    padding-left: calc(20px * var(--device-pixel-ratio));
    height: calc(850px * var(--device-pixel-ratio));
    margin-right: calc(20px * var(--device-pixel-ratio));
    overflow-y: auto;
    & > div {
        width: 100%;
    }
}

.history-round-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: calc(20px * var(--device-pixel-ratio));
    justify-content: left;
}

.round-cards {
    display: flex;
    flex-direction: column;
    gap: calc(8px * var(--device-pixel-ratio));
}

.round-info-item {
    padding-left: calc(12px * var(--device-pixel-ratio));
    border-left: calc(1px * var(--device-pixel-ratio)) solid #87879480;
    gap: calc(20px * var(--device-pixel-ratio));
    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: left;
    }
    .title, .time {
        font: 300 calc(14px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) 'SF Pro Text Light';
        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
        color: #EAEAEC;
        padding: 0;
    }
    .bold-title {
        font: calc(14px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
        color: #EAEAEC;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
    }
    .balanc {
        font: calc(14px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
        color: #FFCC00;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
    }
}

.history-card-list {
    padding: calc(12px * var(--device-pixel-ratio));
    display: flex;
    flex-direction: column;
    gap: calc(4px * var(--device-pixel-ratio));
    border-radius: 8px;
    border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
    height: calc(112px * var(--device-pixel-ratio));
    & .cards-element {
        & .title{
            display: none;
        }
        border: none;
        padding: 0;
        & .cards-list {
            & .cards-list-item {
                width: calc(44px * var(--device-pixel-ratio));
                height: calc(64px * var(--device-pixel-ratio));
                &.cards-list-item:not(:last-child) {
                    margin-right: calc(7px * var(--device-pixel-ratio));
                }
                & .flip-card-back {
                    & .cardIcon {
                        font-size: calc(64px * var(--device-pixel-ratio));
                    }
                }
            }
        }
    }

    &.active {
        border: calc(1px * var(--device-pixel-ratio)) solid rgba(255, 204, 0, 0.5);
    }

    & > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        margin-bottom: calc(8px * var(--device-pixel-ratio));
    }
    .point {
        text-decoration: underline;
        font: bold calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text bold";
        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
        color: #FFFFFF;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
        min-width: calc(80px * var(--device-pixel-ratio));
    }
    .player {
        font: 300 calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) 'SF Pro Text Light';
        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
        color: #FFFFFF;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
        text-transform: capitalize;
    }
}
.history-round {
    .flip-card.active .flip-card-inner {
        transition-delay: 0.2s;
    }
}

.tbl {
    display: flex;
    flex-direction: column;
    gap: calc(4px * var(--device-pixel-ratio));
    width: 100%;
}
.row {
    display: flex;
    align-items: center; 
    min-height: calc(44px * var(--device-pixel-ratio));
    height: calc(44px * var(--device-pixel-ratio));
    background: rgba(135, 135, 148, 0.08) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: calc(7px * var(--device-pixel-ratio));
    &:not(:first-child):not(:last-child) {
        min-height: calc(32px * var(--device-pixel-ratio));
        height: calc(32px * var(--device-pixel-ratio));
    }
}
.cell {
    flex: 1;
    height: 100%;
    text-align: left;
    font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
    letter-spacing: calc(0.56px * var(--device-pixel-ratio));
    color: rgba(234, 234, 236, 1);
    text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.cell.span4-5 {
    flex: 8 calc(24px * var(--device-pixel-ratio));                    /*  col 4,5 flex-grow/border/padding  */
}
.cell.span3-4 {
    flex: 8 calc(24px * var(--device-pixel-ratio));                    /*  col 3,4 flex-grow/border/padding  */
}
.cell.span3-5 {
    flex: 12 calc(36px * var(--device-pixel-ratio));                   /*  col 3,4,5 flex-grow/border/padding  */
}
.row:last-child, .row:first-child{
    display: flex;
    justify-content: left;         /*  left horiz. */
    align-items: center;          /*  center vert. */
    min-height: calc(44px * var(--device-pixel-ratio));
    background: rgba(135, 135, 148, 0.16) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    .cell {
        text-align: left;
        font: bold calc(17px * var(--device-pixel-ratio))/calc(24px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        letter-spacing: calc(0.68px * var(--device-pixel-ratio));
        color: rgba(255, 255, 255, 1);
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
        text-transform: capitalize;
    }
}
.row .cell {
    padding: calc(5px * var(--device-pixel-ratio));
    box-sizing: border-box;
}

.middlePc {
    .history-round {
        padding-left: calc(16px * var(--device-pixel-ratio));
        height: calc(600px * var(--device-pixel-ratio));
        margin-right: calc(16px * var(--device-pixel-ratio));
        overflow-y: auto;
        & > div {
            width: 100%;
        }
    }

    .history-card-list {
        & .cards-element {
            & .cards-list {
                & .cards-list-item {
                    &.cards-list-item:not(:last-child) {
                        margin-right: calc(10px * var(--device-pixel-ratio));
                    }
                }
            }
        }

        & > div:nth-child(1) {

            margin-bottom: calc(0px * var(--device-pixel-ratio));
        }
    }

}

.smallPc {
    .history-round {
        padding-left: calc(16px * var(--device-pixel-ratio));
        height: calc(618px * var(--device-pixel-ratio));
        margin-right: calc(16px * var(--device-pixel-ratio));
        overflow-y: auto;
    }

    .history-card-list {
        & > div:nth-child(1) {
            margin-bottom: calc(0px * var(--device-pixel-ratio));
        }
    }
}


.tab {
    .history-round {
        padding-left: calc(16px * var(--device-pixel-ratio));
        height: calc(803px * var(--device-pixel-ratio));
        margin-right: calc(16px * var(--device-pixel-ratio));
    }

    .history-card-list {
        & .cards-element {
            & .title{
                display: none;
            }
            border: none;
            padding: 0;
        }


        & > div:nth-child(1) {
            margin-bottom: calc(0px * var(--device-pixel-ratio));
        }
    }
}

@media only screen and (max-width: 540px) {
    .modal.history {
        .modal-header-title {
            font: 300 calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) 'SF Pro Text Light';
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
        }
        .title, .time {
            font: 300 calc(10px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) 'SF Pro Text Light';
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
        }
        .bold-title, .balanc {
            font: bold calc(10px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
        }

        .history-round {
            height: 72vh;
            .history-card-list {
                height: calc(84px * var(--device-pixel-ratio));
                padding: calc(8px * var(--device-pixel-ratio));
                & > div:nth-child(1) {
                    margin-bottom: calc(0px * var(--device-pixel-ratio));
                }
            }
            .cards-list {
                width: 100%;
                gap: calc(6px * var(--device-pixel-ratio));

                .card {
                    width: calc(34px * var(--device-pixel-ratio));
                    height: calc(50px * var(--device-pixel-ratio));
    
                    .flip-card-back .cardIcon {
                        font-size: calc(49px * var(--device-pixel-ratio));
                    }
                    &:not(:last-child):not(:first-child) {
                        margin-left: 0;
                    }
                    .flip-card-inner {
                        width: 100%;
                    }
                }
    
            }
        }
    }

    .row {
        min-height: calc(20px * var(--device-pixel-ratio));
        height: calc(20px * var(--device-pixel-ratio));
    }

    .cell {
        font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.4px * var(--device-pixel-ratio));
       
    }

    .row:last-child, .row:first-child {
        min-height: calc(28px * var(--device-pixel-ratio));
        height: calc(28px * var(--device-pixel-ratio));
        .cell {
            font: bold calc(10px * var(--device-pixel-ratio))/calc(17px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
        }
    }
}