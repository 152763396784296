.active-bets {
  position: fixed;
  right: 0;
  top: calc(224px * var(--device-pixel-ratio));
  width: calc(60px * var(--device-pixel-ratio));
  height: calc(260px * var(--device-pixel-ratio));
  background: transparent linear-gradient(180deg, rgba(29, 27, 45, 0.7) 0%, rgba(29, 27, 45, 0.9) 100%) 0% 0% no-repeat padding-box;
  border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
  border-right-color: transparent;
  border-radius: calc(8px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio));
  padding: calc(20px * var(--device-pixel-ratio));
  display: flex;
  flex-direction: row;
  gap: calc(20px * var(--device-pixel-ratio));
  transition: width 0.3s ease-in-out;
  &.opened {
    width: calc(344px * var(--device-pixel-ratio));
  }
  &-header {
    height: calc(220px * var(--device-pixel-ratio));
    width: calc(30px * var(--device-pixel-ratio));
    border-right: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
    &-button {
      transform: rotate(-90deg) translateX(-95%);
      transform-origin: calc(0px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
      flex-direction: row-reverse;
      width: calc(220px * var(--device-pixel-ratio));
      cursor: pointer;
      &:active {
        & svg {
          & .active-bets-icon-bg {
            fill: rgba(84,104,126,0.9);
            stroke: #878794;
          }
        }
      }
      & .title {
        font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.6px * var(--device-pixel-ratio));
        color: rgba(255, 255, 255, 1);
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
        text-transform: uppercase;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & .icon {
          width: calc(18px * var(--device-pixel-ratio));
          height: calc(18px * var(--device-pixel-ratio));
          margin: calc(12px * var(--device-pixel-ratio));
          transform: rotate(-90deg);
          transition: transform 0.3s ease-in-out;
          &.opened {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  &-table {
    width: 0;
    opacity: 0;
    transition: width 0.3s ease-in-out;
    &.opened {
      width: 100%;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
    &.centered {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }
    & table {
      border-spacing: 0;
      width: 100%;
      & thead {
        & tr {
          & th {
            text-align: left;
            font: 300 calc(13px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Light";
            letter-spacing: calc(0.52px * var(--device-pixel-ratio));
            color: rgba(255, 255, 255, 1);
            text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
            padding-bottom: calc(8px * var(--device-pixel-ratio));
            &:nth-child(1) {
              width: 50%;
            }
            &:nth-child(2) {
              width: 25%;
            }
          }
        }
      }
      & tbody {
        & tr {
          & td {
            text-align: left;
            font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.56px * var(--device-pixel-ratio));
            color: rgba(255, 255, 255, 1);
            text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
            padding: calc(14px * var(--device-pixel-ratio)) 0 calc(8px * var(--device-pixel-ratio)) 0;
            border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
            position: relative;
            &:nth-child(1) {
              width: 50%;
            }
            &:nth-child(2) {
              width: 25%;
            }
            &:nth-child(3) {
              text-transform: uppercase;
            }
            & .done {
              position: absolute;
              top: calc(-2px * var(--device-pixel-ratio));
              font: bold calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Bold";
              letter-spacing: calc(0.4px * var(--device-pixel-ratio));
              color: #FFCC00;
              text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    &-body {
      width: 100%;
      height: calc(200px * var(--device-pixel-ratio));
      overflow-y: auto;
    }
    &-no-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .icon {
        width: calc(80px * var(--device-pixel-ratio));
        height: calc(50px * var(--device-pixel-ratio));
        margin-bottom: calc(12px * var(--device-pixel-ratio));
      }
      & .title {
        text-align: center;
        font: bold calc(15px * var(--device-pixel-ratio))/calc(22px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        margin-bottom: calc(12px * var(--device-pixel-ratio));
        letter-spacing: 0px;
        color: #878794;
      }
      border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.2);
    }
  }
}

.middlePc {
  .active-bets {
    top: calc(160px * var(--device-pixel-ratio));
    width: calc(48px * var(--device-pixel-ratio));
    height: calc(204px * var(--device-pixel-ratio));
    padding: calc(16px * var(--device-pixel-ratio));
    gap: calc(16px * var(--device-pixel-ratio));
    &.opened {
      width: calc(268px * var(--device-pixel-ratio));
    }
    &-header {
      height: calc(172px * var(--device-pixel-ratio));
      width: calc(24px * var(--device-pixel-ratio));
      &-button {
        width: calc(172px * var(--device-pixel-ratio));
        & .title {
          font: bold calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Bold";
          letter-spacing: calc(0.56px * var(--device-pixel-ratio));
          & .icon {
            width: calc(16px * var(--device-pixel-ratio));
            height: calc(16px * var(--device-pixel-ratio));
            margin: calc(8px * var(--device-pixel-ratio));
          }
        }
      }
    }
    &-table {
      & table {
        & thead {
          & tr {
            & th {
              font: calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.44px * var(--device-pixel-ratio));
              padding-bottom: calc(6px * var(--device-pixel-ratio));
            }
          }
        }
        & tbody {
          & tr {
            & td {
              font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
              letter-spacing: calc(0.48px * var(--device-pixel-ratio));
              padding: calc(6px * var(--device-pixel-ratio)) 0 calc(8px * var(--device-pixel-ratio)) 0;
              & .done {
                position: absolute;
                top: calc(-2px * var(--device-pixel-ratio));
                font: bold calc(7px * var(--device-pixel-ratio))/calc(10px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                letter-spacing: calc(0.4px * var(--device-pixel-ratio));
                color: #FFCC00;
                text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      &-body {
        height: calc(172px * var(--device-pixel-ratio));
      }
      &-no-data {
        & .icon {
          margin-bottom: calc(10px * var(--device-pixel-ratio));
        }
        & .title {
          margin-bottom: calc(6px * var(--device-pixel-ratio));
        }
      }
    }
  }
}

.smallPc {
  .active-bets {
    top: calc(160px * var(--device-pixel-ratio));
    width: calc(48px * var(--device-pixel-ratio));
    height: calc(204px * var(--device-pixel-ratio));
    padding: calc(16px * var(--device-pixel-ratio));
    gap: calc(16px * var(--device-pixel-ratio));
    &.opened {
      width: calc(268px * var(--device-pixel-ratio));
    }
    &-header {
      height: calc(172px * var(--device-pixel-ratio));
      width: calc(24px * var(--device-pixel-ratio));
      &-button {
        transform: rotate(-90deg) translateX(-95%);
        transform-origin: calc(0px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
        flex-direction: row-reverse;
        width: calc(172px * var(--device-pixel-ratio));
        & .title {
          font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.56px * var(--device-pixel-ratio));
          & .icon {
            width: calc(16px * var(--device-pixel-ratio));
            height: calc(19px * var(--device-pixel-ratio));
            margin: calc(8px * var(--device-pixel-ratio));
          }
        }
      }
    }
    &-table {
      & table {
        & thead {
          & tr {
            & th {
              font: 300 calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Light";
              letter-spacing: calc(0.44px * var(--device-pixel-ratio));
              padding-bottom: calc(6px * var(--device-pixel-ratio));
              &:nth-child(1) {
                width: 40%;
              }
              &:nth-child(2) {
                width: 20%;
              }
            }
          }
        }
        & tbody {
          & tr {
            & td {
              font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.48px * var(--device-pixel-ratio));
              padding: calc(6px * var(--device-pixel-ratio)) 0;
              &:nth-child(1) {
                width: 40%;
              }
              &:nth-child(2) {
                width: 20%;
              }
              & .done {
                font: bold calc(7px * var(--device-pixel-ratio))/calc(9px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                letter-spacing: calc(0.28px * var(--device-pixel-ratio));
              }
            }
          }
        }
      }
      &-body {
        width: 100%;
        height: calc(147px * var(--device-pixel-ratio));
        overflow-y: auto;
      }
    }
  }
}

.tab {
  .active-bets {
    position: unset;
    order: 4;
    width: calc(240px * var(--device-pixel-ratio));
    height: calc(356px * var(--device-pixel-ratio));
    border-bottom-color: transparent;
    padding: calc(16px * var(--device-pixel-ratio));
    flex-direction: column;
    gap: calc(20px * var(--device-pixel-ratio));
    border-radius: calc(8px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio));
    &.opened {
      width: calc(240px * var(--device-pixel-ratio));
      height: calc(356px * var(--device-pixel-ratio));
    }
    &-header {
      height: calc(38px * var(--device-pixel-ratio));
      width: 100%;
      border-right: 0;
      border-bottom:  1px solid rgba(135, 135, 148, 0.5);
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      &-button {
        transform: none;
        transform-origin: unset;
        width: 100%;
        cursor: unset;
        pointer-events: none;
        & .title {
          font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.48px * var(--device-pixel-ratio));
          & .icon {
            display: none;
          }
        }
      }
    }
    &-table {
      width: 100%;
      opacity: 1;
      transition: unset;
      & table {
        border-spacing: 0;
        width: 100%;
        & thead {
          & tr {
            & th {
              text-align: left;
              font: 300 calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Light";
              letter-spacing: calc(0.44px * var(--device-pixel-ratio));
              padding-bottom: calc(8px * var(--device-pixel-ratio));
              &:nth-child(1) {
                width: 50%;
              }
              &:nth-child(2) {
                width: 25%;
              }
            }
          }
        }
        & tbody {
          & tr {
            & td {
              text-align: left;
              font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.48px * var(--device-pixel-ratio));
              padding: calc(8px * var(--device-pixel-ratio)) 0;
              &:nth-child(1) {
                width: 50%;
              }
              &:nth-child(2) {
                width: 25%;
              }
              & .done {
                top: 0;
                font: bold calc(8px * var(--device-pixel-ratio))/calc(10px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                letter-spacing: calc(0.32px * var(--device-pixel-ratio));
              }
            }
          }
        }
      }
      &-body {
        width: 100%;
        height: calc(273px * var(--device-pixel-ratio));
        overflow-y: auto;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .active-bets {
    position: absolute;
    left: 0;
    bottom: 24.28vh;
    top: unset;
    right: unset;
    order: 3;
    width: 41.86vw;
    height: 17.39vh;
    border-left-color: transparent;
    border-right-color: rgba(135, 135, 148, 0.5);
    padding: 2.66vw;
    flex-direction: column;
    gap: 0.899vw;
    border-radius: 0px 8px 8px 0px;
    margin-bottom: 0.899vh;
    &.opened {
      width: 41.86vw;
      height: 17.39vh;
    }
    &-header {
      height: 2.698vh;
      width: 100%;
      border-right: 0;
      border-bottom:  1px solid rgba(135, 135, 148, 0.5);
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      &-button {
        transform: none;
        transform-origin: unset;
        width: 100%;
        cursor: unset;
        pointer-events: none;
        & .title {
          font: 2.66vw/1.799vh "SF Pro Text Medium";
          letter-spacing: 0.1066vw;
          & .icon {
            display: none;
          }
        }
      }
    }
    &-table {
      width: 100%;
      opacity: 1;
      transition: unset;
      & table {
        border-spacing: 0;
        width: 100%;
        & thead {
          & tr {
            & th {
              text-align: left;
              font: 300 2.4vw "SF Pro Text Light";
              letter-spacing: 0.096vw;
              line-height: normal;
              padding-bottom: 0.899vh;
              &:nth-child(1) {
                width: 50%;
              }
              &:nth-child(2) {
                width: 25%;
              }
            }
          }
        }
        & tbody {
          & tr {
            & td {
              text-align: left;
              font: 2.66vw "SF Pro Text Medium";
              line-height: normal;
              letter-spacing: 0.1066vw;
              padding: 0.899vh 0;
              &:nth-child(1) {
                width: 50%;
              }
              &:nth-child(2) {
                width: 25%;
              }
              & .done {
                top: 0;
                font: bold 1.33vw/1.49vh "SF Pro Text Bold";
                letter-spacing: 0.053vw;
              }
            }
          }
        }
      }
      &-body {
        width: 100%;
        height: 9vh;
        overflow-y: auto;
      }
      &-no-data {
        & .icon {
          width: 11.733vw;
          height: auto;
          margin-bottom: 0.599vh;
        }
        & .title {
          font: bold 2.66vw "SF Pro Text Bold";
          margin-bottom: 0.599vh;
        }
      }
    }
  }
}